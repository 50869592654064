import * as ProviderViews from '@uppy/provider-views';
import { Provider } from '@uppy/companion-client';
import GooglePhotos from '.';

const { ProviderViews: ProviderViewsClass } = ProviderViews;

class GooglePhotosProviderViews extends ProviderViewsClass {
  public readonly icon: () => any;

  public constructor(plugin: GooglePhotos, opts: { provider: Provider }) {
    super(plugin, opts);
    this.icon = plugin.icon;
  }

  toggleCheckbox(e: Event, file: any): void {
    e.stopPropagation();
    e.preventDefault();

    // Shared Drives aren't selectable; for all else, defer to the base ProviderView.
    // @todo isTeamDrive is left for backward compatibility. We should remove it in the next
    // major release.
    if (!file.custom.isTeamDrive && !file.custom.isSharedDrive) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      super.toggleCheckbox(e, file);
    }
  }
}

export default GooglePhotosProviderViews;
