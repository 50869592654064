// /** @jsx h */
import { Plugin, Uppy } from '@uppy/core';
import { h } from 'preact';
import { PluginOptions, PluginTarget } from '@uppy/core';
import { Provider, PublicProviderOptions } from '@uppy/companion-client';

export interface ShutterstockOptions
  extends PluginOptions,
    PublicProviderOptions {
  target?: PluginTarget;
  openShutterstock?: () => {
    //
  };
  title?: string;
}

class Shutterstock extends Plugin {
  public readonly title: string;
  public readonly icon: () => any;
  public openShutterstock: () => {
    //
  };

  constructor(uppy: Uppy, private options: ShutterstockOptions) {
    super(uppy, options);

    this.id = options.id ?? 'Shutterstock';
    this.openShutterstock = options.openShutterstock;

    Provider.initPlugin(this, options);

    this.title = options.title ?? 'Shutterstock';

    this.icon = () => (
      <svg
        width="18"
        height="21"
        viewBox="0 0 18 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.8517 4.58317H6.30022C5.57721 4.58317 4.98929 5.1834 4.98929 5.91918V10.9607H0.5V5.91918C0.499899 5.14164 0.649884 4.37169 0.941364 3.65334C1.23284 2.935 1.66012 2.28233 2.19877 1.73263C2.73742 1.18293 3.37689 0.746978 4.08064 0.449686C4.78438 0.152395 5.53861 -0.000410957 6.30022 8.30062e-07H10.8517V4.58317ZM7.14758 16.416H11.7021C12.0491 16.415 12.3816 16.2737 12.6269 16.023C12.8721 15.7724 13.0101 15.4328 13.0107 15.0785V10.0393H17.5V15.0793C17.5 16.6493 16.8892 18.155 15.8019 19.2653C14.7147 20.3756 13.2399 20.9996 11.7021 21H7.14758V16.416Z"
          fill="#EE3124"
        />
      </svg>
    );

    this.render = this.render.bind(this);
    this.install = this.install.bind(this);
  }

  public install(): void {
    const target = this.options.target;

    if (target) {
      this.mount(target, this as any);
    }
  }

  public render(): void {
    this.openShutterstock();
  }
}

export default Shutterstock;
