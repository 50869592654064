function checkImageSupport() {
  const elem = document.createElement('canvas');
  elem.id = 'webp-support';
  const webp = elem.toDataURL('image/webp').indexOf('data:image/webp') === 0;
  const heic = elem.toDataURL('image/heic').indexOf('data:image/heic') === 0;

  return {
    webp, heic
  }
}

const supportedPreviewTypes = new Set(['image/png', 'image/jpeg', 'image/svg+xml']);
const { webp, heic } = checkImageSupport();
if (webp) {
  supportedPreviewTypes.add('image/webp');
}
if (heic) {
  supportedPreviewTypes.add('image/heic');
}

export { supportedPreviewTypes };

export function createWorkerFromInlineCode(code: string): Worker {
  const blob = new Blob([code], { type: 'application/javascript' });
  return new Worker(URL.createObjectURL(blob), { type: 'module' });
}

export function loadImage(url: string) {
  return new Promise<HTMLImageElement>((resolve, reject) => {
    const image = new Image();
    image.onload = () => resolve(image);
    image.onerror = () => reject();
    image.src = url;
  });
}
