/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
// /** @jsx h */
import { Plugin, Uppy } from '@uppy/core';
import { h } from 'preact';
import { Provider, PublicProviderOptions } from '@uppy/companion-client';
import GooglePhotosProviderViews from './GooglePhotosProviderViews';
import { PluginOptions, PluginTarget } from '@uppy/core';

export interface GooglePhotosFileData {
  custom: {
    isSharedAlbum: boolean;
  };
  icon: string;
  id: string;
  isFolder: boolean;
  mimeType: string;
  name: string;
  requestPath: string;
  thumbnail: string;
}

export interface GooglePhotosOptions
  extends PluginOptions,
    PublicProviderOptions {
  title?: string;
  type?: string;
  target?: PluginTarget;
  provider?: string;
}

class GooglePhotos extends Plugin<GooglePhotosOptions> {
  public readonly title: string;
  public readonly icon: () => any;
  public readonly provider: Provider;
  public view: GooglePhotosProviderViews;

  constructor(uppy: Uppy, private options: GooglePhotosOptions) {
    super(uppy, options);

    this.id = options.id ?? 'GooglePhotosPlugin';
    this.title = options.title ?? 'Google Photos';

    Provider.initPlugin(this, options);

    this.title = options.title ?? 'Google Photos';

    this.type = options.type ?? 'acquirer';
    this.icon = () => (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 48 48"
        width="32px"
        height="32px"
      >
        <path
          fill="#f44336"
          d="M37,24h-6.936C26.715,24,24,21.285,24,17.936V0.573c0-0.219,0.26-0.333,0.421-0.184L37,12V24z"
        />
        <path
          fill="#ad1457"
          d="M37,24h-6.936c-1.436,0-2.755-0.499-3.794-1.333L37,12V24z"
        />
        <path
          fill="#8bc34a"
          d="M11,24h6.936C21.285,24,24,26.715,24,30.064v17.362c0,0.219-0.26,0.333-0.421,0.184L11,36V24z"
        />
        <path
          fill="#009688"
          d="M11,24h6.936c1.436,0,2.755,0.499,3.794,1.333L11,36V24z"
        />
        <path
          fill="#ffc107"
          d="M24,11v6.936C24,21.285,21.285,24,17.936,24H0.573c-0.219,0-0.333-0.26-0.184-0.421L12,11H24z"
        />
        <path
          fill="#ff9800"
          d="M24,11v6.936c0,1.436-0.499,2.755-1.333,3.794L12,11H24z"
        />
        <path
          fill="#448aff"
          d="M24,37v-6.936C24,26.715,26.715,24,30.064,24h17.362c0.219,0,0.333,0.26,0.184,0.421L36,37H24z"
        />
        <path
          fill="#1565c0"
          d="M24,37v-6.936c0-1.436,0.499-2.755,1.333-3.794L36,37H24z"
        />
      </svg>
    );
    this.provider = new Provider(uppy, {
      companionUrl: this.options.companionUrl,
      companionHeaders:
        this.options.companionHeaders ?? this.options.serverHeaders ?? {},
      provider: options?.provider ?? 'googlePhotos',
      pluginId: this.id,
    });
    this.render = this.render.bind(this);
    this.install = this.install.bind(this);
  }

  public install(): void {
    this.view = new GooglePhotosProviderViews(this, {
      provider: this.provider,
    });

    const target = this.options.target;

    if (target) {
      this.mount(target, this as any);
    }
  }

  public uninstall(): void {
    (this.view as any).tearDown();
    this.unmount();
  }

  onFirstRender() {
    return (this.view as any).getFolder();
  }

  public render(state: any): unknown {
    const viewOptions = {};
    const pluginState = this.getPluginState();
    const { files, folders } = pluginState as any;

    if (files.length && !folders.length) {
      viewOptions['viewType'] = 'grid';
    }

    return (this.view as any).render(state, viewOptions);
  }
}

export default GooglePhotos;
