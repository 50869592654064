import type { UppyOptions } from '@uppy/core';
import type { DashboardOptions } from '@uppy/dashboard';
import type { StatusBarOptions } from '@uppy/status-bar';
import type { GoogleDriveOptions } from '@uppy/google-drive';
import type { DropboxOptions } from '@uppy/dropbox';
import type { FacebookOptions } from '@uppy/facebook';
import type { InstagramOptions } from '@uppy/instagram';
import type { AwsS3MultipartOptions } from '@uppy/aws-s3-multipart';
import type { GooglePhotosOptions } from './plugins/GooglePhotos';
import type { ShutterstockOptions } from './plugins/Shutterstock';
import type { ThumbnailGeneratorOptions } from './plugins/ThumbnailGenerator';

export enum FileUploaderMessage {
  closed = 'app.uploader-closed',
  opened = 'app.uploader-opened',
  reset = 'app.uploader-reset',
  previewGeneratedSuccess = 'app.preview-generated-success',
  previewGeneratedEmptySuccess = 'app.preview-generated-empty-success',
  previewGeneratedRemotelySuccess = 'app.preview-generated-remotely-success',
  previewGeneratedError = 'app.preview-generated-error',
  previewGeneratedRemotelyError = 'app.preview-generated-remotely-error',
  uploadStart = 'app.upload-start',
  uploadProgress = 'app.upload-progress',
  uploadSuccess = 'app.upload-success',
  uploadError = 'app.upload-error',
  uploadRetry = 'app.upload-retry',
  uploadComplete = 'app.upload-complete',
  openModal = 'app.open-modal',
  closeModal = `app.close-modal`,
  fileAdded = 'app.file-added',
  fileRemoved = 'app.file-removed',
  providerOpened = 'app.provider-opened',
  openShutterstock = 'app.open-shutterstock',
}

export type FileUploaderCallback = (
  FileUploaderMessages: FileUploaderMessage,
  payload?: unknown,
  transfer?: ArrayBuffer[]
) => void;

export interface LocaleOverride {
  strings: {
    [key: string]: string;
  };
}

export interface FileUploaderConfig {
  core?: UppyOptions;
  dashboard?: DashboardOptions | false;
  statusBar?: StatusBarOptions | false;
  googleDrive?: GoogleDriveOptions | false;
  dropbox?: DropboxOptions | false;
  s3?: AwsS3MultipartOptions | false;
  googlePhotos?: GooglePhotosOptions | false;
  shutterstock?: ShutterstockOptions | false;
  facebook?: FacebookOptions | false;
  instagram?: InstagramOptions | false;
  thumbnailGenerator?: ThumbnailGeneratorOptions | false;
  companionUrl?: string;
  allPlugins?: boolean;
  locale?: string;
  hideModalOnUploadStart?: boolean;
  localeOverride?: LocaleOverride;
}
