/* eslint-disable @typescript-eslint/ban-ts-comment */
// /** @jsx h */
import Uppy, { UppyFile, Locale } from '@uppy/core';
import Dashboard from '@uppy/dashboard';
import StatusBar from '@uppy/status-bar';
import GoogleDrive from '@uppy/google-drive';
import Dropbox from '@uppy/dropbox';
import Facebook from '@uppy/facebook';
import Instagram from '@uppy/instagram';
import AwsS3Multipart from '@uppy/aws-s3-multipart';
import generateFileID from '@uppy/utils/lib/generateFileID';
import getFileType from '@uppy/utils/lib/getFileType';
import throttle from 'lodash/throttle';

import GooglePhotos from './plugins/GooglePhotos';
import ThumbnailGenerator from './plugins/ThumbnailGenerator';
import Shutterstock from './plugins/Shutterstock';

// Locales
import locale_de_DE from '@uppy/locales/lib/de_DE';
import locale_en from '@uppy/locales/lib/en_US';
import locale_fr_FR from '@uppy/locales/lib/fr_FR';
import locale_nb_NO from '@uppy/locales/lib/nb_NO';
import locale_sv_SE from '@uppy/locales/lib/sv_SE';
import locale_es_ES from '@uppy/locales/lib/es_ES';
import locale_it_IT from '@uppy/locales/lib/it_IT';
import locale_ja_JP from '@uppy/locales/lib/ja_JP';
import {
  FileUploaderCallback,
  FileUploaderConfig,
  FileUploaderMessage,
  LocaleOverride,
} from './types';

import '@uppy/core/dist/style.min.css';
import '@uppy/dashboard/dist/style.min.css';
import '@uppy/status-bar/dist/style.min.css';
import '../styles/index.css';

const UPDATE_MESSAGE_THROTTLE_TIME = 1000;
const DEFAULT_LOCALE = 'en';

class FileUploader {
  public readonly uppy: Uppy.Uppy<Uppy.TypeChecking>;
  public readonly allPlugins: boolean;
  public readonly dashboard: boolean;
  public readonly companionUrl: string;

  constructor(
    private readonly config: FileUploaderConfig,
    private readonly eventCallback: FileUploaderCallback
  ) {
    this.uppy = Uppy({
      id: 'uppy-uploader',
      ...(this.config.core ?? {}),
    });

    this.allPlugins = this.config?.allPlugins ?? false;
    this.dashboard = this.config?.dashboard !== false;
    this.companionUrl = this.config?.companionUrl;
    this.initPlugins();
    this.initEvents();
    this.initLocale();
  }

  private initDashboard(): void {
    if (this.dashboard || this.allPlugins) {
      this.uppy.use(Dashboard, {
        inline: false,
        height: 300,
        waitForThumbnailsBeforeUpload: false,
        disableThumbnailGenerator: true,
        proudlyDisplayPoweredByUppy: false,
        ...(this.config?.dashboard ?? {}),
      });

      // Monkey patch for setPluginState to be able emit events on opening providers
      // Note! Could be broken, on Uppy version upgrade, if they change internal structure and formats
      const dashboardPlugin = this.uppy.getPlugin('Dashboard');
      const _setPluginState = dashboardPlugin.setPluginState;
      dashboardPlugin.setPluginState = (...params) => {
        const stateUpdate = params[0];
        if (stateUpdate && stateUpdate.activeOverlayType === 'PickerPanel') {
          const providerId =
            (stateUpdate.activePickerPanel &&
              stateUpdate.activePickerPanel.id) ||
            null;
          this.uppy.emit('open-provider', providerId);
        }
        return _setPluginState.apply(dashboardPlugin, params);
      };
    }
  }

  private initStatusBar(): void {
    if (this.config?.statusBar !== false || this.allPlugins) {
      this.uppy.use(StatusBar, {
        id: 'StatusBar',
        target: 'body',
        hideAfterFinish: true,
        showProgressDetails: true,
        hideUploadButton: true,
        hideRetryButton: true,
        hidePauseResumeButton: true,
        hideCancelButton: true,
        ...(this.config?.statusBar ?? {}),
      });
    }
  }

  private initDestination(): void {
    if (this.config?.s3 !== false || this.allPlugins) {
      const companionUrl =
        (this.config?.s3 !== false ? this.config?.s3?.companionUrl : null) ??
        this.companionUrl ??
        'https://uploader-backend.ie.test.gelato.tech/';

      this.uppy.use(AwsS3Multipart, {
        companionUrl: companionUrl,
        ...(this.config?.s3 ?? {}),
      });
    }
  }

  private initGoogleDrive(): void {
    if ((this.config?.googleDrive && this.dashboard) || this.allPlugins) {
      const companionUrl =
        (this.config?.googleDrive !== false
          ? this.config?.googleDrive?.companionUrl
          : null) ??
        this.companionUrl ??
        'https://companion.uppy.io';

      this.uppy.use(GoogleDrive, {
        target: Dashboard,
        companionUrl,
        ...(this.config?.googleDrive ?? {}),
      });
    }
  }

  private initGooglePhotos(): void {
    if (
      (this.config?.googlePhotos !== false && this.dashboard) ||
      this.allPlugins
    ) {
      const companionUrl =
        (this.config?.googlePhotos !== false
          ? this.config?.googlePhotos?.companionUrl
          : null) ??
        this.companionUrl ??
        'https://uploader-backend.ie.test.gelato.tech/';

      this.uppy.use(GooglePhotos, {
        target: Dashboard,
        companionUrl,
        ...(this.config?.googlePhotos ?? {}),
      });
    }
  }

  private initShutterstock(): void {
    if ((this.config?.shutterstock && this.dashboard) || this.allPlugins) {
      let companionUrl =
        this.companionUrl ?? 'https://uploader-backend.ie.test.gelato.tech/';

      if (this.config?.shutterstock) {
        companionUrl = this.config?.shutterstock?.companionUrl;
      }

      this.uppy.use(Shutterstock, {
        target: Dashboard,
        companionUrl,
        ...(this.config?.shutterstock ?? {}),
        openShutterstock: this.openShutterstockTab.bind(this),
      });
    }
  }

  private initFacebook(): void {
    if (
      (this.config?.facebook !== false && this.dashboard) ||
      this.allPlugins
    ) {
      const companionUrl =
        (this.config?.facebook !== false
          ? this.config?.facebook?.companionUrl
          : null) ??
        this.companionUrl ??
        'https://uploader-backend.ie.test.gelato.tech/';

      this.uppy.use(Facebook, {
        target: Dashboard,
        companionUrl,
        ...(this.config?.facebook ?? {}),
      });
    }
  }

  private initInstagram(): void {
    if (
      (this.config?.instagram !== false && this.dashboard) ||
      this.allPlugins
    ) {
      const companionUrl =
        (this.config?.instagram !== false
          ? this.config?.instagram?.companionUrl
          : null) ??
        this.companionUrl ??
        'https://uploader-backend.ie.test.gelato.tech/';

      this.uppy.use(Instagram, {
        target: Dashboard,
        companionUrl,
        ...(this.config?.instagram ?? {}),
      });
    }
  }

  private initDropbox(): void {
    if ((this.config?.dropbox && this.dashboard) || this.allPlugins) {
      const companionUrl =
        (this.config?.dropbox !== false
          ? this.config?.dropbox?.companionUrl
          : null) ??
        this.companionUrl ??
        'https://companion.uppy.io';

      this.uppy.use(Dropbox, {
        target: Dashboard,
        companionUrl,
        ...(this.config?.dropbox ?? {}),
      });
    }
  }

  private initThumbnailGenerator(): void {
    if (this.config?.thumbnailGenerator !== false || this.allPlugins) {
      this.uppy.use(ThumbnailGenerator, {
        thumbnailWidth: 280,
        waitForThumbnailsBeforeUpload: false,
        lazy: false,
        ...(this.config?.thumbnailGenerator ?? {}),
      });
    }
  }

  private initPlugins(): void {
    this.initDashboard();
    this.initStatusBar();
    this.initShutterstock();
    this.initGoogleDrive();
    this.initGooglePhotos();
    this.initFacebook();
    this.initInstagram();
    this.initDropbox();
    this.initDestination();
    this.initThumbnailGenerator();
  }

  private initLocale(): void {
    this.updateLocale(
      this.config?.locale ?? null,
      this.config?.localeOverride ?? null
    );
  }

  private getLocale(localeString: string | null): Locale {
    const supportedLocales: { [key: string]: Locale } = {
      de_DE: locale_de_DE,
      en: locale_en,
      fr_FR: locale_fr_FR,
      nb_NO: locale_nb_NO,
      sv_SE: locale_sv_SE,
      es_ES: locale_es_ES,
      it_IT: locale_it_IT,
      ja_JP: locale_ja_JP,
    };

    let localeName = DEFAULT_LOCALE;
    if (localeString && typeof supportedLocales[localeString] !== 'undefined') {
      localeName = localeString;
    }
    return supportedLocales[localeName];
  }

  private initUploadEvents(): void {
    this.uppy.on('upload', (result: { id: string; fileIDs: string[] }) => {
      this.eventCallback(FileUploaderMessage.uploadStart, {
        id: result.id,
        fileIDs: result.fileIDs,
        files: result.fileIDs.map((fileID) => this.uppy.getFile(fileID)),
      });
      if (this.config?.hideModalOnUploadStart ?? true) {
        this.close();
      }
    });

    this.uppy.on('upload-success', async (file: UppyFile, response) => {
      try {
        this.eventCallback(FileUploaderMessage.uploadSuccess, {
          file,
          url: response.uploadURL,
        });
      } catch (error) {
        console.error(error);

        this.eventCallback(FileUploaderMessage.uploadError);
      }
    });

    this.uppy.on(
      'upload-error',
      (file: UppyFile, error: string | Error, response): void => {
        this.eventCallback(FileUploaderMessage.uploadError, {
          file,
          error,
          response,
        });
      }
    );

    const onUploadProgress = (
      file: UppyFile,
      progress: {
        uploader: unknown;
        bytesUploaded: number;
        bytesTotal: number;
      }
    ) => {
      this.eventCallback(FileUploaderMessage.uploadProgress, {
        file,
        progress,
      });
    };
    const onUploadMap: { [key: string]: (...args: any[]) => any } = {};

    this.uppy.on(
      'upload-progress',
      (
        file: UppyFile,
        progress: {
          uploader: unknown;
          bytesUploaded: number;
          bytesTotal: number;
        }
      ) => {
        if (!onUploadMap[file.id]) {
          onUploadMap[file.id] = throttle(
            onUploadProgress,
            UPDATE_MESSAGE_THROTTLE_TIME
          );
        }
        onUploadMap[file.id](file, progress);
      }
    );

    this.uppy.on('upload-retry', (fileID: string) => {
      this.eventCallback(FileUploaderMessage.uploadRetry, fileID);
    });

    this.uppy.on(
      'complete',
      (result: { successful: UppyFile[]; failed: UppyFile[] }) => {
        this.eventCallback(FileUploaderMessage.uploadComplete, result);
      }
    );
  }

  private initModalEvents(): void {
    this.uppy.on('dashboard:modal-closed', () => {
      this.eventCallback(FileUploaderMessage.closed);
    });

    this.uppy.on('dashboard:modal-open', () => {
      this.eventCallback(FileUploaderMessage.opened);
    });

    this.uppy.on('open-provider', (providerId) => {
      this.eventCallback(FileUploaderMessage.providerOpened, providerId);
    });
  }

  private initThumbnailEvents(): void {
    this.uppy.on(
      'thumbnail:generated',
      (file: UppyFile) => this.eventCallback(FileUploaderMessage.previewGeneratedSuccess, file)
    );

    this.uppy.on('thumbnail:generatedRemote', (file: UppyFile): void => {
      const fileObject = this.uppy.getFile(file.id);

      if (!fileObject.preview) {
        return;
      }
      this.eventCallback(
        FileUploaderMessage.previewGeneratedRemotelySuccess,
        file
      );
    });

    this.uppy.on(
      'thumbnail:generatedEmpty',
      (file: UppyFile): void => {
        this.eventCallback(FileUploaderMessage.previewGeneratedEmptySuccess, file);
      }
    );
  }

  private initFileEvents(): void {
    this.uppy.on('file-added', (file: UppyFile) => {
      this.uppy.emit('thumbnail:request', file);
      this.eventCallback(FileUploaderMessage.fileAdded, file);
      if (file.isRemote) {
        if (file.preview) {
          this.eventCallback(
            FileUploaderMessage.previewGeneratedRemotelySuccess,
            file
          );
        } else {
          this.eventCallback(
            FileUploaderMessage.previewGeneratedRemotelyError,
            file
          );
        }
      }
    });

    this.uppy.on('file-removed', (file: UppyFile) => {
      this.eventCallback(FileUploaderMessage.fileRemoved, file);
    });
  }

  private initEvents(): void {
    this.initFileEvents();
    this.initUploadEvents();
    this.initModalEvents();
    this.initThumbnailEvents();
  }

  public show(): void {
    const dashboardPlugin = this.uppy.getPlugin('Dashboard');

    if (dashboardPlugin) {
      (dashboardPlugin as Dashboard).openModal();
      this.eventCallback(FileUploaderMessage.openModal);
    }
  }

  public close(): void {
    const dashboardPlugin = this.uppy.getPlugin('Dashboard');

    if (dashboardPlugin) {
      (dashboardPlugin as Dashboard).closeModal();
      this.eventCallback(FileUploaderMessage.closeModal);
    }
  }

  public openShutterstockTab(): void {
    this.eventCallback(FileUploaderMessage.openShutterstock);
    this.close();
  }

  public reset(): void {
    this.uppy.reset();
    this.eventCallback(FileUploaderMessage.reset);
  }

  public updateLocale(
    newLocale: string,
    override: LocaleOverride | null
  ): void {
    const localeObject: Locale = this.getLocale(newLocale);
    if (override) {
      localeObject.strings = {
        ...localeObject.strings,
        ...override.strings,
      };
    }
    this.uppy.setOptions({
      locale: localeObject,
    });
  }
}

export default FileUploader;

export { generateFileID, getFileType };
