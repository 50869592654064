import { Uppy, Plugin, PluginOptions } from '@uppy/core';
import Translator from '@uppy/utils/lib/Translator';
import isObjectURL from '@uppy/utils/lib/isObjectURL';
import thumbnailWorker, { ThumbnailWorkerInput, ThumbnailWorkerOutput, ThumbnailWorkerSuccessOutput } from './thumbnailWorker.worker';
import exifr from 'exifr';
import { supportedPreviewTypes, createWorkerFromInlineCode, loadImage } from './utils';

const MAX_IMAGE_AREA = 4096 * 4096;

export interface ThumbnailGeneratorOptions extends PluginOptions {
  thumbnailWidth?: number;
  thumbnailHeight?: number;
  waitForThumbnailsBeforeUpload?: boolean;
  lazy?: boolean;
  thumbnailType?: string;
  clearMemoryAfterUpload?: boolean;
}


type ThumbnailData = Omit<ThumbnailWorkerSuccessOutput, 'id' | 'state'>;

interface IRotation {
  deg: number;
  rad: number;
  scaleX: number;
  scaleY: number;
  dimensionSwapped: boolean;
  css: boolean;
  canvas: boolean;
}

/**
 * The Thumbnail Generator plugin
 */
class ThumbnailGenerator extends Plugin<ThumbnailGeneratorOptions> {
  readonly title: string;
  opts: ThumbnailGeneratorOptions;
  queue: string[];
  queueProcessing: boolean;
  defaultThumbnailDimension: number;
  clearMemoryAfterUpload: boolean;
  thumbnailType: string;
  translator: Translator;
  i18n: any;
  onFileAddedBinded = this.onFileAdded.bind(this);
  onCancelRequestBinded = this.onCancelRequest.bind(this);
  onFileRemovedBinded = this.onFileRemoved.bind(this);
  onRestoredBinded = this.onRestored.bind(this);
  waitUntilAllProcessedBinded = this.waitUntilAllProcessed.bind(this);

  // worker
  private worker: Worker;
  private promises: {
    [id: string]: { resolve: (data: ThumbnailData) => void; reject: (err: string) => void };
  } = {};
  

  constructor(uppy: Uppy, opts: ThumbnailGeneratorOptions) {
    super(uppy, opts);
    this.type = 'modifier';
    this.id = this.opts.id || 'ThumbnailGenerator';
    this.title = 'Thumbnail Generator';
    this.queue = [];
    this.queueProcessing = false;
    this.defaultThumbnailDimension = 200;
    this.thumbnailType = this.opts.thumbnailType || 'image/jpeg';
    this.clearMemoryAfterUpload = this.opts.clearMemoryAfterUpload ?? true;

    const defaultOptions = {
      thumbnailWidth: null,
      thumbnailHeight: null,
      waitForThumbnailsBeforeUpload: false,
      lazy: false,
    };

    this.opts = { ...defaultOptions, ...opts };

    if (this.opts.lazy && this.opts.waitForThumbnailsBeforeUpload) {
      throw new Error(
        'ThumbnailGenerator: The `lazy` and `waitForThumbnailsBeforeUpload` options are mutually exclusive. Please ensure at most one of them is set to `true`.'
      );
    }

    if (typeof OffscreenCanvas !== 'undefined') {
      try {
        this.worker = createWorkerFromInlineCode(thumbnailWorker);
        this.worker.addEventListener('message', this.onWorkerMessage.bind(this));
        this.worker.addEventListener('error', (e) => {
          console.error(e);
        });
      } catch {
        // failed to create worker, fallback to main thread
      }
    }
  }

  private onWorkerMessage(e) {
    const output = e.data as ThumbnailWorkerOutput;
    const promise = this.promises[output.id];
    if (output.state === 'error') {
      promise.reject(output.error);
    } else {
      promise.resolve(output)
    }
  }

  private minimizeCanvasMemoryUsage(
    canvas: HTMLCanvasElement
  ): void {
    canvas.height = 0;
    canvas.width = 0;
  }

  private isBrowserSupportsImageOrientation(): boolean {
    const initialValue = getComputedStyle(document.body).imageOrientation;
    return initialValue === 'from-image';
  }

  /**
   * Create a thumbnail for the given Uppy file object.
   */
  private async createThumbnail(
    file: { data: File, id: string },
    targetWidth: number,
    targetHeight: number
  ): Promise<{ preview: string | undefined; width: number; height: number }> {
    if (this.worker && file.data.type !== 'image/svg+xml') {
      try {
        const workerInput: ThumbnailWorkerInput = { file: file.data, id: file.id };
        this.worker.postMessage(workerInput);
        return new Promise<ThumbnailData>((resolve, reject) => {
          this.promises[file.id] = { resolve, reject };
        }).then((data) => {
          return {
            preview: data.url,
            width: data.width,
            height: data.height,
          };
        });
      } catch {
        // failed to generate in worker, fallback to main thread
      }
    }

    const originalUrl = URL.createObjectURL(file.data);

    try {
      const orientationPromise = exifr.rotation(file.data).catch(() => ({ deg: 0 }));
      const [image, orientation] = await Promise.all([loadImage(originalUrl), orientationPromise]);
      const { width, height } = image;

      if (Math.max(width, height) > MAX_IMAGE_AREA) {
        return {
          preview: undefined,
          width,
          height,
        };
      }

      const dimensions = this.getProportionalDimensions(
        image,
        targetWidth,
        targetHeight,
        orientation
      );

      const allowTransparency = this.thumbnailType === 'image/png';
      const originalAspectRatio = image.width / image.height;
      const correctAspectRatio = dimensions.width / dimensions.height;
      const isImageDataRotated =
        Math.abs(1 - originalAspectRatio / correctAspectRatio) > 0.01 &&
        !this.isBrowserSupportsImageOrientation();

      const resizedImage = isImageDataRotated
        ? this.resizeImage(
            image,
            dimensions.height,
            dimensions.width,
            allowTransparency
          )
        : this.resizeImage(
            image,
            dimensions.width,
            dimensions.height,
            allowTransparency
          );

      const rotatedImage = this.isBrowserSupportsImageOrientation()
        ? resizedImage
        : this.rotateImage(resizedImage, orientation, allowTransparency);

      URL.revokeObjectURL(originalUrl);

      try {
        const blob = await new Promise<Blob>((resolve, reject) => {
          rotatedImage.toBlob((blob) => {
            if (!blob) {
              return reject(new Error('Could not create thumbnail'));
            }
            resolve(blob);
          }, this.thumbnailType);
        });

        this.minimizeCanvasMemoryUsage(resizedImage);
        this.minimizeCanvasMemoryUsage(rotatedImage);
  
        return {
          preview: URL.createObjectURL(blob),
          width,
          height,
        };
      } catch (err) {
        console.error(err);
        return {
          preview: undefined,
          width,
          height,
        };
      }
    } catch (err) {
      console.error(err);
      URL.revokeObjectURL(originalUrl);
      throw err;
    }
  }

  /**
   * Get the new calculated dimensions for the given image and a target width
   * or height. If both width and height are given, only width is taken into
   * account. If neither width nor height are given, the default dimension
   * is used.
   */
  private getProportionalDimensions(
    img: HTMLImageElement,
    width: number,
    height: number,
    rotation: Partial<IRotation>
  ): { width: number; height: number } {
    let aspect = img.width / img.height;
    if (
      (rotation.deg === 90 || rotation.deg === 270) &&
      !this.isBrowserSupportsImageOrientation()
    ) {
      aspect = img.height / img.width;
    }

    if (width != null) {
      return {
        width: width,
        height: Math.round(width / aspect),
      };
    }

    if (height != null) {
      return {
        width: Math.round(height * aspect),
        height: height,
      };
    }

    return {
      width: this.defaultThumbnailDimension,
      height: Math.round(this.defaultThumbnailDimension / aspect),
    };
  }

  /**
   * Resize an image to the target `width` and `height`.
   *
   * Returns a Canvas with the resized image on it.
   */
  private resizeImage(
    image: HTMLImageElement,
    targetWidth: number,
    targetHeight: number,
    allowTransparency: boolean
  ): HTMLCanvasElement {
    const canvas = document.createElement('canvas');
    canvas.width = targetWidth;
    canvas.height = targetHeight;

    const context = canvas.getContext('2d')
    if (!allowTransparency) {
      context.fillStyle = 'white';
      context.fillRect(0, 0, canvas.width, canvas.height);
    }
    context.drawImage(image, 0, 0, canvas.width, canvas.height);
    return canvas;
  }

  private rotateImage(
    image: HTMLCanvasElement,
    orientation: Partial<IRotation>,
    allowTransparency: boolean
  ) {
    if (orientation.deg === 0) {
      return image;
    }

    let w = image.width;
    let h = image.height;

    if (orientation.deg === 90 || orientation.deg === 270) {
      w = image.height;
      h = image.width;
    }

    const canvas = document.createElement('canvas');
    canvas.width = w;
    canvas.height = h;

    const context = canvas.getContext('2d');
    if (!allowTransparency) {
      context.fillStyle = 'white';
      context.fillRect(0, 0, canvas.width, canvas.height);
    }

    context.translate(w / 2, h / 2);
    if (orientation.canvas) {
      context.rotate(orientation.rad);
      context.scale(orientation.scaleX, orientation.scaleY);
    }
    context.drawImage(
      image,
      -image.width / 2,
      -image.height / 2,
      image.width,
      image.height
    );

    return canvas;
  }

  private addToQueue(item) {
    this.queue.push(item);
    if (this.queueProcessing === false) {
      this.processQueue();
    }
  }

  private processQueue() {
    this.queueProcessing = true;
    if (this.queue.length > 0) {
      const current = this.uppy.getFile(this.queue.shift());
      if (!current) {
        this.uppy.log(
          '[ThumbnailGenerator] file was removed before a thumbnail could be generated, but not removed from the queue. This is probably a bug',
          'error'
        );
        return;
      }
      return this.requestThumbnail(current)
        .catch((err) => {
          console.error(err);
        })
        .then(() => this.processQueue());
    } else {
      this.queueProcessing = false;
      this.uppy.log('[ThumbnailGenerator] Emptied thumbnail queue');
      this.uppy.emit('thumbnail:all-generated');
    }
  }

  requestThumbnail(file) {
    if (supportedPreviewTypes.has(file.type) && !file.isRemote) {
      return this.createThumbnail(
        file,
        this.opts.thumbnailWidth,
        this.opts.thumbnailHeight
      )
        .then(({ preview, width, height }) => {
          this.uppy.setFileState(file.id, { preview, width, height });

          if (!preview) {
            return this.uppy.emit('thumbnail:generatedEmpty', this.uppy.getFile(file.id));
          }

          this.uppy.log(`[ThumbnailGenerator] Generated thumbnail for ${file.id}`);
          this.uppy.emit('thumbnail:generated', this.uppy.getFile(file.id));
        })
        .catch((err) => {
          this.uppy.log(
            `[ThumbnailGenerator] Failed thumbnail for ${file.id}:`,
            'warning'
          );
          this.uppy.log(err, 'warning');
          this.uppy.emit('thumbnail:error', this.uppy.getFile(file.id), err);
        });
    }
    return Promise.resolve();
  }

  private onFileAdded(file) {
    if (!file.preview && supportedPreviewTypes.has(file.type) && !file.isRemote) {
      this.addToQueue(file.id);
    }
  }

  /**
   * Cancel a lazy request for a thumbnail if the thumbnail has not yet been generated.
   */
  private onCancelRequest(file) {
    const index = this.queue.indexOf(file.id);
    if (index !== -1) {
      this.queue.splice(index, 1);
    }
  }

  /**
   * Clean up the thumbnail for a file. Cancel lazy requests and free the thumbnail URL.
   */
  private onFileRemoved(file) {
    const index = this.queue.indexOf(file.id);
    if (index !== -1) {
      this.queue.splice(index, 1);
    }

    // Clean up object URLs.
    if (this.clearMemoryAfterUpload && file.preview && isObjectURL(file.preview)) {
      URL.revokeObjectURL(file.preview);
    }
  }

  private onRestored() {
    const { files } = this.uppy.getState();
    const fileIDs = Object.keys(files);
    fileIDs.forEach((fileID) => {
      const file = this.uppy.getFile(fileID);
      if (!(file as any).isRestored) return;
      // Only add blob URLs; they are likely invalid after being restored.
      if (!file.preview || isObjectURL(file.preview)) {
        this.addToQueue(file.id);
      }
    });
  }

  private waitUntilAllProcessed(fileIDs) {
    fileIDs.forEach((fileID) => {
      const file = this.uppy.getFile(fileID);
      const i18n = (this.uppy as any)?.i18n;
      this.uppy.emit('preprocess-progress', file, {
        mode: 'indeterminate',
        message: 'Generating thumbnails...',
      });
    });

    const emitPreprocessCompleteForAll = () => {
      fileIDs.forEach((fileID) => {
        const file = this.uppy.getFile(fileID);
        this.uppy.emit('preprocess-complete', file);
      });
    };

    return new Promise<void>((resolve) => {
      if (this.queueProcessing) {
        this.uppy.once('thumbnail:all-generated', () => {
          emitPreprocessCompleteForAll();
          resolve();
        });
      } else {
        emitPreprocessCompleteForAll();
        resolve();
      }
    });
  }

  install() {
    this.uppy.on('file-removed', this.onFileRemovedBinded);
    if (this.opts.lazy) {
      this.uppy.on('thumbnail:request', this.onFileAddedBinded);
      this.uppy.on('thumbnail:cancel', this.onCancelRequestBinded);
    } else {
      this.uppy.on('file-added', this.onFileAddedBinded);
      this.uppy.on('restored', this.onRestoredBinded);
    }

    if (this.opts.waitForThumbnailsBeforeUpload) {
      this.uppy.addPreProcessor(this.waitUntilAllProcessedBinded);
    }
  }

  uninstall() {
    this.uppy.off('file-removed', this.onFileRemovedBinded);
    if (this.opts.lazy) {
      this.uppy.off('thumbnail:request', this.onFileAddedBinded);
      this.uppy.off('thumbnail:cancel', this.onCancelRequestBinded);
    } else {
      this.uppy.off('file-added', this.onFileAddedBinded);
      this.uppy.off('restored', this.onRestoredBinded);
    }

    if (this.opts.waitForThumbnailsBeforeUpload) {
      this.uppy.removePreProcessor(this.waitUntilAllProcessedBinded);
    }
  }
}

export default ThumbnailGenerator;
